import filterselect from '../../molecules/filter-select/filter-select';

class DocumentSearchFilter {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.id = this.$el.getAttribute('id');
    this.$fieldsContainer = this.$el.querySelector('.filter__fields');
    this.dataSrc = this.$el.dataset.src;
    this.dataStr = this.$el.querySelector('.filter__data').textContent;

    if (this.dataSrc) {
      this.loadFilterData();
    } else {
      return false;
    }
  }

  async loadFilterData() {
    // const response = await fetch(this.dataSrc);
    // this.data = await response.json();
    this.data = JSON.parse(this.dataStr);

    this.initDependentFilter();
  }

  initDependentFilter() {
    this.bindedTypeFilterChange = this.typeFilterChange.bind(this);
    this.bindedProducttypeFilterChange = this.producttypeFilterChange.bind(this);
    this.bindedProducttypeversionFilterChange = this.producttypeversionFilterChange.bind(this);

    this.typeselected = this.$el.dataset.typeselected;
    this.producttypeselected = this.$el.dataset.producttypeselected;
    this.producttypeversionselected = this.$el.dataset.producttypeversionselected;

    this.typeId = `${this.id}-type`;
    this.producttypeId = `${this.id}-producttype`;
    this.producttypeversionId = `${this.id}-producttypeversion`;

    this.typeIdValue = this.typeselected;

    this.typelabels = {
      ProdT: 'Produkttyp',
      AnbT: 'Anbietertyp',
      Anw: 'Anwendungstyp',
    };

    const typeConsts = [
      {
        value: 'ProdT',
        text: 'Produkttyp',
        selected: (this.typeselected === 'ProdT') && 'selected',
      },
      {
        value: 'AnbT',
        text: 'Anbietertyp',
        selected: (this.typeselected === 'AnbT') && 'selected',
      },
      {
        value: 'Anw',
        text: 'Weitere Anwendungen',
        selected: (this.typeselected === 'Anw') && 'selected',
      },
    ];

    const options = [];

    const entries = Object.entries(this.data);

    // eslint-disable-next-line no-restricted-syntax
    for (const entry of entries) {
      // console.log(entry);
      const option = typeConsts.find(
        obj => obj.value == entry[0], // eslint-disable-line eqeqeq
      );

      options.push({
        value: option.value,
        text: option.text,
        selected: option.selected,
      });
    }

    const $filterSelect = this.getDependentFilterSelect('Typ', options, 'tx_gemcharacteristics_productlist[type]', this.typeId);

    if (this.typeselected) {
      // this.typeId = this.typeselected;
      this.typeSlice = this.data[this.typeselected];
    }

    this.$fieldsContainer.appendChild($filterSelect);
    document.getElementById(this.typeId).addEventListener('change', this.bindedTypeFilterChange);

    if (this.producttypeselected || this.typeselected) {
      this.producttypeSlice = this.typeSlice.find(
        obj => obj.value == this.producttypeselected, // eslint-disable-line eqeqeq
      );

      const $productTypeFilterSelect = this.getDependentFilterSelect(this.typelabels[this.typeselected], this.typeSlice, 'tx_gemcharacteristics_productlist[producttype]', this.producttypeId);
      this.$fieldsContainer.appendChild($productTypeFilterSelect);

      if (this.producttypeselected) {
        document.getElementById(this.producttypeId).querySelector(`option[value="${this.producttypeselected}"`).selected = true;
      }

      document.getElementById(this.producttypeId).addEventListener('change', this.bindedProducttypeFilterChange);
    }

    if (this.producttypeversionselected || this.producttypeselected) {
      const $productTypeVersionFilterSelect = this.getDependentFilterSelect(`${this.typelabels[this.typeselected]}version`, this.producttypeSlice.versions, 'tx_gemcharacteristics_productlist[producttypeversion]', this.producttypeversionId);
      this.$fieldsContainer.appendChild($productTypeVersionFilterSelect);

      if (this.producttypeversionselected) {
        document.getElementById(this.producttypeversionId).querySelector(`option[value="${this.producttypeversionselected}"`).selected = true;
        this.$el.querySelector('.filter__button').disabled = false;
      }

      document.getElementById(this.producttypeversionId).addEventListener('change', this.bindedProducttypeversionFilterChange);
    }
  }

  typeFilterChange(event) {
    this.typeIdValue = event.target.value;
    this.typeSlice = this.data[this.typeIdValue];

    this.$el.querySelector('.filter__button').disabled = true;

    if (this.data[this.typeIdValue]) {
      const $filterSelect = this.getDependentFilterSelect(this.typelabels[this.typeIdValue], this.typeSlice, 'tx_gemcharacteristics_productlist[producttype]', this.producttypeId);

      if (document.getElementById(this.producttypeId)) {
        const $elToRemove = document.getElementById(this.producttypeId).closest('.filter__field');
        $elToRemove.parentNode.removeChild($elToRemove);
      }

      if (document.getElementById(this.producttypeversionId)) {
        const $elToRemove = document.getElementById(this.producttypeversionId).closest('.filter__field');
        $elToRemove.parentNode.removeChild($elToRemove);
      }

      this.$fieldsContainer.appendChild($filterSelect);

      document.getElementById(this.producttypeId).addEventListener('change', this.bindedProducttypeFilterChange);
    }
  }

  producttypeFilterChange(event) {
    this.producttypeIdValue = event.target.value;
    this.producttypeSlice = this.typeSlice.find(
      obj => obj.value == this.producttypeIdValue, // eslint-disable-line eqeqeq
    );

    this.$el.querySelector('.filter__button').disabled = true;

    if (this.producttypeSlice.versions) {
      const $filterSelect = this.getDependentFilterSelect(`${this.typelabels[this.typeIdValue]}version`, this.producttypeSlice.versions, 'tx_gemcharacteristics_productlist[producttypeversion]', this.producttypeversionId);

      if (document.getElementById(this.producttypeversionId)) {
        const $elToRemove = document.getElementById(this.producttypeversionId).closest('.filter__field');
        $elToRemove.parentNode.removeChild($elToRemove);
      }

      this.$fieldsContainer.appendChild($filterSelect);

      document.getElementById(this.producttypeversionId).addEventListener('change', this.bindedProducttypeversionFilterChange);
    } else if (document.getElementById(this.producttypeversionId)) {
      const $elToRemove = document.getElementById(this.producttypeversionId).closest('.filter__field');
      $elToRemove.parentNode.removeChild($elToRemove);
    }
  }

  producttypeversionFilterChange() {
    this.$el.querySelector('.filter__button').disabled = false;
  }

  getDependentFilterSelect(label, items, name, id) {
    const $filterSelect = filterselect({
      id,
      labelText: label,
      name,
      options: items,
    });

    $filterSelect.querySelector('.filter-select__select').addEventListener('change', this.bindedDependentFilterSelectChange);

    return $filterSelect;
  }
}

document.querySelectorAll('.js-documentsearch-filter').forEach($el => new DocumentSearchFilter($el));
