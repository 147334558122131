import { BREAKPOINTS } from 'javascripts/constants';
import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class ToggleButton {
  constructor($toggleButton) {
    this.$toggleButton = $toggleButton;
    this.$panel = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));
    this.$closeButton = this.$panel.querySelector('.navigation__close');
    this.$navigationMainBlock = this.$panel.querySelector('.navigation__main-block');
    this.mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedClose = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$toggleButton.addEventListener('click', this.bindedClick);
    this.$closeButton.addEventListener('click', this.bindedClose);
  }

  init() {
    // Init focus trap
    let focusTrapTarget = this.$panel;

    if (this.mql.matches) {
      focusTrapTarget = this.$panel.querySelector('.navigation__main');

      // Re-position close button
      this.desktopCloseButton();
    }

    this.focusTrap = createFocusTrap(focusTrapTarget, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
    });
  }

  desktopCloseButton() {
    if (this.$navigationMainBlock) {
      const closeButton = this.$closeButton;
      this.$navigationMainBlock.parentNode.insertBefore(closeButton, this.$navigationMainBlock);
    }
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  panelClick(event) {
    if (event.target.closest('.navigation__main--jumplist')) {
      this.closePanel();
      return;
    }

    if (this.mql.matches) {
      if (event.target.closest('.navigation__main-inner')) {
        return;
      }
    } else if (event.target.closest('.navigation')) {
      return;
    }

    this.closePanel();
  }

  closePanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Close Search Panel
    this.$panel.classList.remove('header__panel--open');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    this.$closeButton.removeEventListener('click', this.bindedClose);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Show search panel
    this.$panel.classList.add('header__panel--open');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);
    this.$closeButton.addEventListener('click', this.bindedClose);

    // Disable scrolling
    disableBodyScroll(this.$panel);

    // Enable focus trap
    this.focusTrap.activate();

    // Focus close Button insted of the search field which is focused by default to prevent
    // Keyboard from popping up on mobile devices
    this.$closeButton.focus();
  }
}
