export default class TabBoxContent {
  constructor($tabBoxContent) {
    this.$tabBoxContent = $tabBoxContent;
    this.$tabBoxContent.addEventListener('selectTab', this.onSelectTab.bind(this));

    this.$tabBoxContent.classList.add('tab-box--initialized');
  }

  onSelectTab(event) {
    // Get current panel
    const $currentPanel = this.$tabBoxContent.querySelector('.tab-box__panel:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = this.$tabBoxContent.querySelector(event.target.getAttribute('href'));
    const $panel = $headline.closest('.tab-box__panel');

    // Make panel visible
    $panel.removeAttribute('hidden');
    $headline.focus();
  }
}

document.querySelectorAll('.tab-box').forEach($el => new TabBoxContent($el));
