export default class ToggleFormFieldsGroup {
  constructor($el) {
    this.$toggleButton = $el;
    this.$controls = document.querySelector(`#${this.$toggleButton.getAttribute('aria-controls')}`);

    this.$toggleButton.addEventListener('click', this.toggleFieldsGroup.bind(this));

    const expanded = this.$toggleButton.getAttribute('aria-expanded');
    if (expanded === 'false') {
      const $fields = this.$controls.querySelectorAll('.input, .select, .checkbox__input, .radio__input');
      $fields.forEach(($field) => {
        $field.setAttribute('disabled', 'disabled');
      });
    }
  }

  toggleFieldsGroup() {
    const expanded = this.$toggleButton.getAttribute('aria-expanded');
    if (expanded === 'false') {
      this.$toggleButton.setAttribute('aria-expanded', 'true');
      this.$controls.classList.remove('form__fields-group--hidden');
      const $fields = this.$controls.querySelectorAll('.input, .select, .checkbox__input, .radio__input');
      $fields.forEach(($field) => {
        $field.removeAttribute('disabled');
      });
    } else {
      this.$toggleButton.setAttribute('aria-expanded', 'false');
      this.$controls.classList.add('form__fields-group--hidden');
      const $fields = this.$controls.querySelectorAll('.input, .select, .checkbox__input, .radio__input');
      $fields.forEach(($field) => {
        $field.setAttribute('disabled', 'disabled');
      });
    }
  }
}
