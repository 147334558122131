document.querySelectorAll('.knowledgebase-list-filter').forEach(($dropdown) => {
  const $toggle = $dropdown.querySelector('.knowledgebase-list-filter__button');
  const $listFilterContent = $dropdown.querySelector('.knowledgebase-list-filter__content');
  const mql = [
    window.matchMedia('(max-width: 1259px)'),
  ];

  const isXL = mql[0].matches;

  const toggleDropdown = (event) => {
    const $target = event.currentTarget;
    event.stopPropagation();

    if ($target) {
      const dropdownContentVisible = $toggle.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($toggle.getAttribute('aria-controls'));

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!dropdownContentVisible).toString());

      // Switch content visiblity
      if (dropdownContentVisible) {
        $content.classList.remove('knowledgebase-list-filter--visible');
      } else {
        $content.classList.add('knowledgebase-list-filter--visible');
      }
    }
  };

  if (isXL) {
    $toggle.setAttribute('aria-expanded', false);
    $listFilterContent.classList.remove('knowledgebase-list-filter--visible');
  }

  mql.forEach(((el) => {
    el.addListener(toggleDropdown);
  }));

  if ($toggle) {
    $toggle.addEventListener('click', toggleDropdown);
  }
});
