// import { BREAKPOINTS } from 'javascripts/constants';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createFocusTrap } from 'focus-trap';

document.querySelectorAll('.list-filter-group').forEach(($toggle) => {
  const listFilterGroupHeader = $toggle.querySelector('.list-filter-group__button');
  const filterPanel = document.getElementById(listFilterGroupHeader.getAttribute('aria-controls'));
  const listFilterGroupItems = filterPanel.querySelector('.list-filter-group__items');
  const closeButton = $toggle.querySelector('.list-filter-group__close');
  const focusTrapTarget = filterPanel.querySelector('.list-filter-group__content-inner');

  const focusTrap = createFocusTrap(focusTrapTarget, {
    escapeDeactivates: false,
    clickOutsideDeactivates: true,
    returnFocusOnDeactivate: false,
  });

  const closePanel = () => {
    listFilterGroupHeader.setAttribute('aria-expanded', false);
    $toggle.classList.remove('list-filter-group--open');

    // Disable focus trap
    if (focusTrap) {
      focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(listFilterGroupItems);
  };

  const onOutsideClick = (event) => {
    if (event.target.closest('.list-filter-group__content-inner')) {
      return;
    }
    closePanel();
  };

  const openPanel = () => {
    listFilterGroupHeader.setAttribute('aria-expanded', true);
    $toggle.classList.add('list-filter-group--open');

    // Outside click
    filterPanel.addEventListener('click', onOutsideClick);

    // De-enable scrolling
    disableBodyScroll(listFilterGroupItems);

    // Disable focus trap
    if (focusTrap) {
      focusTrap.activate();
    }
  };

  listFilterGroupHeader.addEventListener('click', (event) => {
    const $target = event.currentTarget;

    if ($target) {
      const listFilterGroupVisible = listFilterGroupHeader.getAttribute('aria-expanded') === 'true';
      // Switch content visiblity
      if (listFilterGroupVisible) {
        closePanel();
      } else {
        openPanel();
      }
    }
  });

  closeButton.addEventListener('click', () => {
    closePanel();
  });

  document.addEventListener('keydown', (event) => {
    // Close Filter overlay on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      closePanel();
    }
  });
});
