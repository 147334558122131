import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';
import classNames from 'classnames';
import iconButton from '../../components/atoms/icon-button/icon-button';

export default class Slider {
  constructor($el, options) {
    this.$el = $el;

    // Classes have to be assigned to slider elements
    if (!options) {
      return;
    }

    this.options = {
      rewind: true,
      slidesToScroll: 1,
      slidesFocusable: true,
      autoplay: false,
      autoplayTime: 5000,
      ...options,
    };

    // Slides
    this.$slides = this.$el.querySelectorAll(`.${this.options.sliderSlide}`);

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.slidesTotal = this.$slides.length;

    this.direction = false;

    this.initSlider();
  }

  initClasses() {
    // Frame
    this.$sliderFrame = this.$el.querySelector(`.${this.options.sliderFrame}`);
    // Container: Collection of slides
    this.$slidesContainer = this.$el.querySelector(`.${this.options.slidesContainer}`);
    // Controls-Frame: Where to dock the controls
    this.$controlsFrame = this.$el.querySelector(`.${this.options.sliderControlsframe}`);

    // Add slider classes
    this.$controlsFrame.classList.add('slider__controls');
    this.$sliderFrame.classList.add('slider__frame');
    this.$slidesContainer.classList.add('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.setAttribute('tabindex', '0');
        }
        $item.classList.add('slider__slide');
      });
    }
  }

  initSlider() {
    this.initClasses();

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
      this.initDots();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateDots();
      this.$el.classList.add('slider--operated');
    });

    this.$slidesContainer.addEventListener('focusin', this.focusIn);

    this.lory = lory(this.$el, {
      slidesToScroll: this.options.slidesToScroll,
      rewind: this.options.rewind,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: this.options.sliderFrame,
      classNameSlideContainer: this.options.slidesContainer,
      classNamePrevCtrl: 'slider__control--prev',
      classNameNextCtrl: 'slider__control--next',
    });

    if (this.options.autoplay) {
      this.initAutoplay();
    }
  }

  initControls() {
    const $controlPrev = iconButton({
      icon: 'arrow-left',
      title: 'Vorherige',
      large: true,
      tabIndex: '-1',
      classes: ['slider__control', 'slider__control--prev'],
    });

    const $controlNext = iconButton({
      icon: 'arrow-right',
      title: 'Nächste',
      large: true,
      tabIndex: '-1',
      classes: ['slider__control', 'slider__control--next'],
    });

    $controlPrev.addEventListener('click', () => {
      this.direction = 'backward';
      this.autoplayStop();
    });

    $controlNext.addEventListener('click', () => {
      this.direction = 'forward';
      this.autoplayStop();
    });

    this.$controlsFrame.innerHTML = '';
    this.$controlsFrame.appendChild($controlPrev);
    this.$controlsFrame.appendChild($controlNext);
  }

  initDots() {
    const dotAmount = Math.ceil(this.slidesTotal / this.options.slidesToScroll);
    const $dots = h('div.slider__dots');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dotAmount; i++) {
      const $dot = h('button', {
        type: 'button',
        tabIndex: '-1',
        className: classNames(
          'slider__dot',
          i === 0 && 'slider__dot--active',
        ),
      }, h('span.slider__dot-text', `Slide ${i}`));

      $dot.addEventListener('click', () => {
        this.lory.slideTo(i);
        this.autoplayStop();
      });

      $dots.appendChild($dot);
    }

    this.$controlsFrame.appendChild($dots);
  }

  updateDots() {
    const currentIndex = this.lory.returnIndex();

    this.$el.querySelectorAll('.slider__dot').forEach(($dot, i) => {
      $dot.classList.remove('slider__dot--active');

      if (Math.ceil(currentIndex / this.options.slidesToScroll) === i) {
        $dot.classList.add('slider__dot--active');
      }
    });
  }

  initAutoplay() {
    this.autoplayStartBinded = this.autoplayStart.bind(this);
    this.autoplayStopBinded = this.autoplayStop.bind(this);
    this.autoplayTime = this.options.autoplayTime;
    this.autoplayPlays = false;

    this.$el.addEventListener('mouseenter', this.autoplayStopBinded);
    this.$el.addEventListener('mouseleave', this.autoplayStartBinded);

    this.autoplayStart();
  }

  autoplayStop() {
    if (this.lory) {
      clearInterval(this.autoplayInverval);
      this.autoplayPlays = false;
    }
  }

  autoplayStart() {
    if (this.lory) {
      this.autoplayInverval = setInterval(() => {
        this.lory.next();
      }, this.autoplayTime);

      this.autoplayPlays = true;
    }
  }

  updateTeaserPosition = (index) => {
    this.lory.slideTo(index);
  };

  focusIn = (event) => {
    if (event.target && !this.mousedown) {
      this.$sliderFrame.scrollLeft = 0;
      const $slide = event.target.closest('.slider__slide');
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.activeIndex = index;
      this.updateTeaserPosition(index);
    }
  }

  deconstructor() {
    const $controls = this.$el.querySelectorAll('.slider__control');
    $controls.forEach(($el) => {
      $el.remove();
    });

    const $dots = this.$el.querySelector('.slider__dots');
    if ($dots) {
      $dots.remove();
    }

    this.$el.classList.remove('slider--initialized');
    this.$el.classList.remove('slider--operated');
    this.$sliderFrame.classList.remove('slider__frame');
    this.$slidesContainer.classList.remove('slider__container');
    this.$slidesContainer.removeAttribute('style');
    this.$controlsFrame.classList.remove('slider__controls');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.removeAttribute('tabindex', '0');
        }
        $item.classList.remove('slider__slide');
      });
    }

    this.$el.removeEventListener('mouseenter', this.autoplayStopBinded);
    this.$el.removeEventListener('mouseleave', this.autoplayStartBinded);
    clearInterval(this.autoplayInverval);

    if (this.lory) {
      this.lory.destroy();
      this.lory = false;
    }
  }
}
