import { BREAKPOINTS } from 'javascripts/constants';

document.querySelectorAll('.list-filter').forEach(($dropdown) => {
  const $toggle = $dropdown.querySelector('.list-filter__button');
  const mql = window.matchMedia(`(max-width: ${BREAKPOINTS.xl})`);

  const toggleDropdown = (event) => {
    const $target = event.currentTarget;
    event.stopPropagation();

    if ($target) {
      const dropdownContentVisible = $toggle.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($toggle.getAttribute('aria-controls'));

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!dropdownContentVisible).toString());

      // Switch content visiblity
      if (dropdownContentVisible) {
        $content.classList.remove('list-filter__content--visible');
      } else {
        $content.classList.add('list-filter__content--visible');
      }
    }
  };

  const onMediaQueryChange = () => {
    $toggle.setAttribute('aria-expanded', true);
    $dropdown.querySelector('.list-filter__content').classList.add('list-filter__content--visible');
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  onMediaQueryChange(mql);

  if ($toggle) {
    $toggle.addEventListener('click', toggleDropdown);
  }
});
