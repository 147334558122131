import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';

export default class FormSteps {
  constructor($formSteps) {
    this.$formSteps = $formSteps;
    this.$formStepsForm = this.$formSteps.querySelector('.form-steps__form');
    this.$tabsContainer = this.$formSteps.querySelector('.form-steps__tabs');
    this.$tabsInner = this.$formSteps.querySelector('.form-steps__tabs-inner');
    this.$tabs = this.$tabsInner.querySelectorAll('.form-steps__tab-link');
    // eslint-disable-next-line prefer-destructuring
    this.$tabActive = this.$tabs[0];
    this.$stepsButtons = this.$formSteps.querySelectorAll('.form__buttons');
    this.$formStepsSections = this.$formSteps.querySelector('.form-steps__sections');
    this.$formStepsHeaders = this.$formSteps.querySelector('.form-steps__headers');
    this.$formFieldGroupToggle = this.$formSteps.querySelectorAll('.js-toggle-form-fields-group');

    this.initFormSteps();
  }

  initFormSteps() {
    this.$tabsContainer.setAttribute('id', 'form-steps-tabs');
    this.$stepsButtons.forEach(($stepButtonsContainer) => {
      $stepButtonsContainer.addEventListener('click', this.onItem.bind(this));
    });
    this.$tabsInner.addEventListener('click', this.onItem.bind(this));
    this.$tabsInner.addEventListener('keydown', this.onItemKeydown.bind(this));
    window.addEventListener('hashchange', this.onHashchange.bind(this));
    this.$formSteps.addEventListener('resendEvents', this.onResendEvents.bind(this));
    this.$formFieldGroupToggle.forEach(($toggle) => {
      $toggle.addEventListener('click', this.initGroupToggleClick.bind(this));
    });

    // Binded events
    this.bindedObserveReqFieldChange = this.observeReqFieldChange.bind(this);

    // Select tab per URL parameter
    this.onHashchange();

    // Or select tab if Hash has changed
    // window.addEventListener('hashchange', this.onHashchange(), false);

    // Set Tab-Status info
    this.initTabStatus();
  }

  initGroupToggleClick() {
    this.setTabStatus(this.$tabActive);
  }

  initTabStatus() {
    // Each Tab shows the validation status of elements inside of the Tab's Content Form
    this.$tabs.forEach(($tab) => {
      const $tabInner = $tab.querySelector('.form-steps__tab-inner');
      const $tabInnerInfo = h('.form-steps__tab-validation-info');
      const $tabInnerStatus = h('.form-steps__tab-validation-status');
      const $tabInnerAlert = h('.form-steps__tab-validation-alert');
      $tabInner.append($tabInnerInfo, $tabInnerStatus, $tabInnerAlert);

      const $tabTarget = this.$formStepsSections.querySelector($tab.getAttribute('href'));
      const $panel = $tabTarget.closest('.form-steps__section');
      const $panelFields = $panel.querySelectorAll('.input, .select, .checkbox__input, .radio__input');
      if (!$tab.classList.contains('form-steps__tab-link--active')) {
        $tab.classList.add('form-steps__tab-link--inactive');
      }
      this.setTabStatus($tab);

      if ($panelFields) {
        // Observe required fields
        $panelFields.forEach(($field) => {
          const mutationObserver = new MutationObserver(this.bindedObserveReqFieldChange);
          mutationObserver.observe($field, { attributes: true });
        });
      }
    });
  }

  observeReqFieldChange(mutationsList) {
    // Observe changes to required fields for the current Step
    // Set engagement status for the current step
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName === 'data-valid') {
        const $section = mutation.target.closest('.form-steps__section');
        $section.dataset.validationEngagement = 'true';
        const $tab = document.querySelector(`#${$section.getAttribute('aria-labelledby')}`);
        this.setTabStatus($tab);
      }
    });
  }

  setTabStatus($tab) {
    const $tabValidationInfo = $tab.querySelector('.form-steps__tab-validation-info');
    const $tabTarget = this.$formStepsSections.querySelector($tab.getAttribute('href'));
    const $panel = $tabTarget.closest('.form-steps__section');
    const $panelReqFields = $panel.querySelectorAll('[required="required"]:not([disabled="disabled"])');
    const $panelValidReqFields = $panel.querySelectorAll('[required="required"][data-valid="true"]:not([disabled="disabled"])');
    const $panelInvalidFields = $panel.querySelectorAll('[data-valid="false"][aria-invalid="true"]:not([disabled="disabled"])');

    // Count required fields with unique name attribute
    // Required to display sensible number of required fields
    // Taking i.e. Radio-Buttons, that usually have the same name, into account
    const panelReqFieldsUniqueNames = [];
    $panelReqFields.forEach(($el) => {
      if (!panelReqFieldsUniqueNames.includes($el.name)) {
        panelReqFieldsUniqueNames.push($el.name);
      }
    });

    const panelValidReqFieldsUniqueNames = [];
    $panelValidReqFields.forEach(($el) => {
      if (!panelValidReqFieldsUniqueNames.includes($el.name)) {
        panelValidReqFieldsUniqueNames.push($el.name);
      }
    });

    if ($panelInvalidFields.length) {
      // Add status icon to indicate invalid fields
      $tab.classList.add('form-steps__tab-link--alert');
    } else {
      // remove status icon to indicate invalid fields
      $tab.classList.remove('form-steps__tab-link--alert');
    }

    if ($panelReqFields.length > 0) {
      $tabValidationInfo.innerText = `${panelValidReqFieldsUniqueNames.length}/${panelReqFieldsUniqueNames.length} Pflichtfelder`;

      if ($panelValidReqFields.length < $panelReqFields.length) {
        $tab.classList.add('form-steps__tab-link--incomplete');
        $tab.classList.remove('form-steps__tab-link--complete');
      } else if ($panelValidReqFields.length === $panelReqFields.length) {
        $tab.classList.remove('form-steps__tab-link--incomplete');
        $tab.classList.add('form-steps__tab-link--complete');
      }
    }
  }

  onSelectTab($target) {
    // const $target = getTarget(event.target, '.form-steps__tab-link');

    // Get current panel
    const $currentPanel = this.$formStepsSections.querySelector('.form-steps__section:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = this.$formStepsSections.querySelector($target.getAttribute('href'));
    const $panel = $headline.closest('.form-steps__section');

    // Make panel visible
    $panel.removeAttribute('hidden');
    $headline.focus();

    // Get current header
    const $currentHeader = this.$formStepsHeaders.querySelector('.form-steps__header:not([hidden])');

    // Hide current header
    $currentHeader.setAttribute('hidden', true);

    // Make header visible
    const $targetHeader = this.$formStepsHeaders.querySelector(`#form-steps-header-${$target.dataset.id}`);
    $targetHeader.removeAttribute('hidden');

    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  onItem(event) {
    let $target = false;
    $target = getTarget(event.target, '.form-steps__tab-link');

    // Probably click on form button
    if (!$target) {
      const $stepNavTarget = getTarget(event.target, '.form-steps__step-nav');
      if (!$stepNavTarget) return;

      event.preventDefault();

      const $stepNavTargetHash = $stepNavTarget.hash;
      $target = this.$tabsInner.querySelector(`.form-steps__tab-link[href="${$stepNavTargetHash}"]`);
    }

    if ($target) {
      // If target is on the same page, prevent navigation
      if (this.isSamePage($target)) {
        event.preventDefault();
      }

      // Remove old active item
      this.$tabs.forEach(($tab) => {
        if ($tab.classList.contains('form-steps__tab-link--active')) {
          $tab.classList.remove('form-steps__tab-link--active');
          this.$tabActive = null;

          const $headline = this.$formStepsSections.querySelector($tab.getAttribute('href'));
          const $panel = $headline.closest('.form-steps__section');

          if ($panel.dataset.validationEngagement) {
            $tab.classList.add('form-steps__tab-link--wasactive');
          } else {
            $tab.classList.add('form-steps__tab-link--inactive');
          }
        }
        $tab.removeAttribute('aria-selected');
        $tab.removeAttribute('aria-checked');
      });

      // Set new active
      $target.classList.remove('form-steps__tab-link--inactive');
      $target.classList.add('form-steps__tab-link--active');
      this.$tabActive = $target;

      this.onSelectTab($target);
    }
  }

  onItemKeydown(event) {
    const $target = getTarget(event.target, '.form-steps__tab-link');

    if (!$target) {
      return;
    }

    // Down
    if (event.keyCode === 40) {
      event.preventDefault();
      this.focusNext($target, this.$tabs[0]);
    }

    // Up
    if (event.keyCode === 38) {
      event.preventDefault();
      this.focusNext($target, this.$tabs[this.$tabs.length - 1]);
    }
  }

  onHashchange() {
    this.openByHash();
  }

  openByHash() {
    if (window.location.hash) {
      const $target = document.getElementById(window.location.hash.substring(1));

      if ($target && $target.matches('.form-steps__tab-link')) {
        $target.click();
        return true;
      }
    }

    return false;
  }

  onResendEvents() {
    if (!this.openByHash()) {
      const $item = this.$tabsInner.querySelector('.form-steps__tab-link[aria-selected="true"]');
      if ($item) {
        this.onItem({
          target: $item,
          preventDefault: () => { },
        }, true);
      }
    }
  }

  focusNext($currentItem, $startItem) {
    // Determine which item is the startItem (first or last)
    const down = $startItem === this.$tabs[0];

    // Helper function for getting next legitimate element
    const move = ($el) => {
      const $nextThing = (down
        ? $el.parentNode.nextElementSibling : $el.parentNode.previousElementSibling);

      return ($nextThing && $nextThing.firstElementChild) || $startItem;
    };

    // Move and focus
    const $nextItem = move($currentItem);
    $nextItem.focus();
  }

  isSamePage($link) {
    return (
      $link.protocol !== window.location.protocol
      || $link.host !== window.location.host
      || $link.pathname !== window.location.pathname
      || $link.search !== window.location.search
    ) === false;
  }
}

document.querySelectorAll('.form-steps').forEach($formSteps => new FormSteps($formSteps));
