export default class Multiselect {
  constructor($el) {
    this.$el = $el;
    this.elId = this.$el.getAttribute('id');
    this.elFromId = `${this.elId}-1`;
    this.$from = document.getElementById(this.elFromId);
    this.$fromOptionsOrig = this.$from.querySelectorAll('option');
    this.elToId = `${this.elId}-2`;
    this.$to = document.getElementById(this.elToId);
    this.$toOptionsOrig = this.$to.querySelectorAll('option');
    this.$buttonAdd = this.$el.querySelector('.multiselect__button--add');
    this.$buttonRemove = this.$el.querySelector('.multiselect__button--remove');

    this.init();
  }

  init() {
    // Add and Remove button actions
    this.$buttonAdd.addEventListener('click', this.add.bind(this));
    this.$buttonRemove.addEventListener('click', this.remove.bind(this));

    // Add keyup to the to-select
    this.$to.addEventListener('keyup', this.tofieldKeyup.bind(this));

    // Add doubleclick eventlisteners
    this.$fromOptionsOrig.forEach(($option) => {
      $option.addEventListener('dblclick', this.optionDoubleclick.bind(this));
    });

    this.$toOptionsOrig.forEach(($option) => {
      $option.addEventListener('dblclick', this.optionDoubleclick.bind(this));
    });

    // Remove chosen options from selectable options
    if (this.$toOptionsOrig.length) {
      this.setFromOptions();
    }

    // Order the from Options list based on data-order
    this.orderFromOptions();
  }

  resetFromOptions() {
    const $from = document.getElementById(`${this.elId}-1`);
    while ($from.firstChild) {
      $from.removeChild($from.firstChild);
    }

    this.$fromOptionsOrig.forEach(($option) => {
      const $clonedOption = $option.cloneNode(true);
      $clonedOption.addEventListener('dblclick', this.optionDoubleclick.bind(this));
      $from.appendChild($clonedOption);
    });

    this.setFromOptions();
  }

  orderFromOptions() {
    // Order options by data-order attribute
    const $currentFrom = document.getElementById(`${this.elId}-1`);
    const $currentFromOptions = $currentFrom.querySelectorAll('option');

    $currentFromOptions.forEach(($option, index) => {
      if ($option.dataset.order === undefined) {
        $option.setAttribute('data-order', index);
      }
    });

    Array.from($currentFromOptions)
      .sort((a, b) => a.dataset.order - b.dataset.order)
      .forEach(e => e.parentNode.appendChild(e));
  }

  setFromOptions() {
    const $currentTo = document.getElementById(`${this.elId}-2`);
    const $currentToOptions = $currentTo.querySelectorAll('option');
    const $from = document.getElementById(`${this.elId}-1`);

    $currentToOptions.forEach(($option) => {
      const optionValue = $option.getAttribute('value');
      const $fromTargetEl = $from.querySelector(`[value="${optionValue}"]`);
      $option.setAttribute('selected', 'selected');
      if ($fromTargetEl) {
        $option.setAttribute('data-order', $fromTargetEl.dataset.order);
        $from.removeChild($fromTargetEl);
      }
    });

    $currentTo.blur();
    this.$buttonAdd.blur();
  }

  add() {
    const $from = document.getElementById(`${this.elId}-1`);
    const $to = document.getElementById(`${this.elId}-2`);
    const selected = $from.selectedOptions;

    Array.from(selected).forEach(($option) => {
      const $clonedOption = $option.cloneNode(true);

      $from.removeChild($option);
      $clonedOption.addEventListener('dblclick', this.optionDoubleclick.bind(this));
      $to.appendChild($clonedOption);
    });

    $to.focus();

    $to.querySelectorAll('option').forEach(($option) => {
      const $el = $option;
      $el.setAttribute('selected', 'selected');
      $el.selected = true;
    });

    $to.blur();
    this.$buttonAdd.blur();
    this.orderFromOptions();
  }

  remove() {
    const $to = document.getElementById(`${this.elId}-2`);
    const selected = $to.selectedOptions;

    Array.from(selected).forEach(($option) => {
      $to.removeChild($option);
    });

    $to.querySelectorAll('option').forEach(($option) => {
      const $el = $option;
      $el.setAttribute('selected', 'selected');
      $el.selected = true;
    });

    this.resetFromOptions();
    this.$buttonRemove.blur();
    this.orderFromOptions();
  }

  optionDoubleclick(event) {
    const parentNodeId = event.target.parentNode.id;

    if (parentNodeId === this.elFromId) {
      this.add();
    } else if (parentNodeId === this.elToId) {
      this.remove();
    }
  }

  tofieldKeyup(event) {
    const key = event.keyCode;

    if (key === 46 || key === 8) {
      this.remove();
    }
  }
}

// eslint-disable-next-line arrow-body-style
export const attachMultiselect = ($el) => { return new Multiselect($el); };

document.querySelectorAll('.js-multiselect-new').forEach($el => new Multiselect($el));
