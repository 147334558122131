/**
 * ina frontend
 *
 * @author ressourcenmangel an der Panke GmbH <adp@ressourcenmangel.de>
 */

// Shims
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.closest';
import 'focus-visible';
// Shared
import './globals/overlay-link';
import './globals/print';
import './globals/popup';

// Components
import 'components/atoms/tooltip/tooltip';
import 'components/atoms/sort/sort';
import 'components/molecules/figure/figure';
import 'components/molecules/tab-bar/tab-bar';
import 'components/molecules/to-top/to-top';
import 'components/molecules/teaser/teaser';
import 'components/molecules/modal/modal';
import 'components/molecules/collapsible/collapsible';
import 'components/molecules/list-filter/list-filter';
import 'components/molecules/knowledgebase-filter/knowledgebase-filter';
import 'components/molecules/rating-fieldset/rating-fieldset';
import 'components/molecules/multiselect/multiselect';
import 'components/organisms/accordion/accordion';
import 'components/organisms/slider-content/slider-content';
import 'components/organisms/stage-slider/stage-slider';
import 'components/organisms/newsbanner/newsbanner';
import 'components/organisms/header/header';
import 'components/organisms/glossary-content/glossary-content';
import 'components/organisms/table/table';
import 'components/organisms/filter/filter';
import 'components/organisms/list-filter-group/list-filter-group';
import 'components/organisms/list/list';
import 'components/organisms/glossary/glossary';
import 'components/organisms/steps/steps';
import 'components/organisms/tab-box/tab-box';
import 'components/organisms/knowledgebase-list-filter/knowledgebase-list-filter';
import 'components/organisms/form/form';
import 'components/organisms/form-steps/form-steps';
import 'components/organisms/user-area/user-area';

import {SELECTOR_EVENTSLIDER, Eventslider} from 'components/molecules/eventslider/eventslider';
const eventslider = document.querySelectorAll(SELECTOR_EVENTSLIDER);
if (eventslider.length > 0) {
    new Eventslider(eventslider);
}