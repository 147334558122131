class Steps {
  constructor($el) {
    this.$el = $el;
    this.$toggle = this.$el.querySelector('.steps__toggle-button');

    if (!this.$toggle) {
      return false;
    }

    this.toggleControls = this.$toggle.getAttribute('aria-controls');
    this.$panel = document.getElementById(this.toggleControls);
    this.$toggle.setAttribute('aria-expanded', 'false');
    this.$el.classList.add('steps--initialized');

    this.$toggleText = this.$toggle.querySelector('.button__text');

    this.$toggle.addEventListener('click', (e) => {
      e.preventDefault();
      const expanded = this.$toggle.getAttribute('aria-expanded');

      if (expanded === 'false') {
        this.$panel.classList.add('steps__inner--opened');
        this.$toggle.setAttribute('aria-expanded', 'true');
        const { textLess } = this.$toggle.dataset;
        this.$toggleText.innerHTML = textLess || 'Weniger';
      } else {
        this.$panel.classList.remove('steps__inner--opened');
        this.$toggle.setAttribute('aria-expanded', 'false');
        const { textMore } = this.$toggle.dataset;
        this.$toggleText.innerHTML = textMore || 'Mehr';
      }
    });
  }
}

document.querySelectorAll('.steps').forEach($el => new Steps($el));
