import { BREAKPOINTS } from 'javascripts/constants';
import throttle from '_throttle';

export default class HeaderSticky {
  constructor($panel) {
    this.$page = document.querySelector('.page');
    this.$header = document.querySelector('.header');
    this.$headerInner = this.$header.querySelector('.header__inner');
    this.$headerNavigation = this.$header.querySelector('.header__navigation');
    this.$navigation = document.querySelector('.navigation');
    this.$navigationMainSub = document.querySelector('.navigation__main-sublist');
    this.$navigationSearch = document.querySelector('.navigation__search');
    this.$navigationMeta = document.querySelector('.navigation__meta');
    this.$panel = $panel;
    this.mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

    this.mobileInitialized = false;

    const scroll = window.scrollY || window.pageYOffset;

    // Calculate sticky-header change point
    const headerRect = this.$header.getBoundingClientRect();
    const headerTopPos = Math.round(headerRect.top + scroll);
    this.headerChange = headerTopPos;

    // Calculate main navigation change point (only from XL)
    if (this.$navigationMain) {
      const mainnavRect = this.$navigationMain.getBoundingClientRect();
      const mainnavBottomPos = Math.round(mainnavRect.height);
      this.mainnavChange = mainnavBottomPos;
    }

    // Binded events
    this.bindedScroll = throttle(this.scroll.bind(this), 100);

    // Add events
    document.addEventListener('scroll', this.bindedScroll, { passive: true });

    this.init();
  }

  init() {
    this.$header.classList.remove('header--sticky');

    // Check for initial scrollposition
    this.scroll();
  }

  deconstructor() {
    // Remove events
    document.removeEventListener('scroll', this.bindedScroll);
  }

  scroll() {
    const scroll = window.scrollY || window.pageYOffset;

    if (scroll >= this.headerChange) {
      this.$header.classList.add('header--sticky');

      if (this.mql.matches) {
        this.$page.setAttribute('style', 'padding-top: 14rem;');
      } else {
        this.$page.setAttribute('style', 'padding-top: 9rem;');
      }
    } else if (scroll < this.headerChange) {
      this.$header.classList.remove('header--sticky');
      this.$page.removeAttribute('style');
    }
  }
}
