import throttle from '_throttle';

const SELECTOR_TOTOP = '#bJS_to-top';

const changeTop = 200;

const toTop = () => {
  const $toTop = document.querySelector(SELECTOR_TOTOP);
  const scrollY = window.scrollY || window.pageYOffset;

  if (scrollY > changeTop) {
    $toTop.classList.add('to-top--visible');
  } else if (scrollY < changeTop) {
    $toTop.classList.remove('to-top--visible');
  }
};

if (!document.documentElement.classList.contains('kb-preview')) {
  document.addEventListener('scroll', throttle(toTop), { passive: true });
}
