import h from 'hyperscript';
import { attachTooltip } from '../../atoms/tooltip/tooltip';
import { attachMultiselect } from '../../molecules/multiselect/multiselect';

export default class MultiplicateFormFieldsGroup {
  constructor($el) {
    this.$multiplicateButton = $el;
    this.$controls = document.querySelector(`#${this.$multiplicateButton.getAttribute('aria-controls')}`);
    if (!this.$controls) {
      // eslint-disable-next-line no-console
      console.error(`Missing #${this.$multiplicateButton.getAttribute('aria-controls')}`);
      return false;
    }

    this.$initialElement = this.$controls.querySelector('.form__fields-group-item');
    if (!this.$initialElement) {
      // eslint-disable-next-line no-console
      console.error(`Missing Initial group item in #${this.$multiplicateButton.getAttribute('aria-controls')}`);
      return false;
    }

    this.initialElementCount = this.$controls.querySelectorAll('.form__fields-group-item').length;
    this.maxElementCount = parseInt(this.$controls.dataset.maxItems, 10) || 10;

    this.$multiplicateButton.addEventListener('click', this.multiplicateFieldsGroup.bind(this));

    this.init();
  }

  init() {
    if (this.initialElementCount === this.maxElementCount) {
      this.disableButton();
    }
  }

  disableButton() {
    this.$multiplicateButton.setAttribute('disabled', 'disabled');
    const $disabledMsg = h('p.text.text--small', 'Maximale Zahl erreicht');
    this.$multiplicateButton.parentNode.append($disabledMsg);
  }

  enableButton() {
    this.$multiplicateButton.removeAttribute('disabled');
  }

  multiplicateFieldsGroup() {
    const currentRows = this.$controls.childElementCount;
    const newIndex = currentRows + 1;
    const $clone = this.$initialElement.cloneNode(true);

    const $cloneHeadline = $clone.querySelector('.headline');
    const $cloneFields = $clone.querySelectorAll('.input, .select:not(.js-multiselect-new), .checkbox__input, .radio__input');
    const $cloneMultiselect = $clone.querySelectorAll('.js-multiselect-new');
    const $cloneTooltips = $clone.querySelectorAll('.tooltip');

    // "Prepend" newIndex to headline
    if ($cloneHeadline) {
      const cloneHeadelineText = $cloneHeadline.innerHTML;
      $cloneHeadline.innerHTML = `${newIndex}. ${cloneHeadelineText}`;
    }

    $cloneFields.forEach(($field) => {
      const fieldId = $field.getAttribute('id');
      const fieldName = $field.getAttribute('name');

      const matches = fieldName.match(/\w+/g);
      const fieldNewName = fieldName.replace(`[${matches[3]}]`, `[${currentRows}]`);

      const $fieldLabel = $clone.querySelector(`.label[for="${fieldId}"]`);
      if ($fieldLabel) {
        $fieldLabel.setAttribute('for', `${fieldId}_${newIndex}`);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Missing .label for field ${fieldName}`);
      }

      $field.setAttribute('id', `${fieldId}_${newIndex}`);
      $field.setAttribute('name', fieldNewName);
      $field.setAttribute('value', '');
      $field.value = ''; // eslint-disable-line
    });

    // Clone .js-multiselect
    $cloneMultiselect.forEach(($multiselect) => {
      const multiselectId = $multiselect.getAttribute('id');
      const cloneMultiselectId = `${multiselectId}_${newIndex}`;

      const $multiselectCols = $multiselect.querySelectorAll('.multiselect__col');
      $multiselectCols.forEach(($col, index) => {
        const suffix = `-${index + 1}`;
        const $colLabel = $col.querySelector('.label');
        const $colSelect = $col.querySelector('.multiselect__select');
        const $colHiddenField = $col.querySelector('input[type="hidden"]');

        if ($colLabel) {
          $colLabel.setAttribute('for', `${cloneMultiselectId}${suffix}`);
        } else {
          // eslint-disable-next-line no-console
          console.error('Missing .label in .multiselect__col');
        }

        if ($colSelect) {
          const colSelectName = $colSelect.getAttribute('name');
          const matches = colSelectName.match(/\w+/g);
          const colSelectNewName = colSelectName.replace(`[${matches[3]}]`, `[${currentRows}]`);

          $colSelect.setAttribute('id', `${cloneMultiselectId}${suffix}`);
          $colSelect.setAttribute('name', colSelectNewName);
        } else {
          // eslint-disable-next-line no-console
          console.error('Missing .multiselect__select in .multiselect__col');
        }

        if ($colHiddenField) {
          const colHiddenFieldName = $colHiddenField.getAttribute('name');
          const matches2 = colHiddenFieldName.match(/\w+/g);
          const colHiddenFieldNewName = colHiddenFieldName.replace(`[${matches2[3]}]`, `[${currentRows}]`);
          $colHiddenField.setAttribute('name', colHiddenFieldNewName);
        } else {
          // eslint-disable-next-line no-console
          console.error('Missing input[type="hidden"] in .multiselect__col');
        }
      });

      const $multiselectFrom = $multiselectCols[0].querySelector('.multiselect__select');
      const $multiselectTo = $multiselectCols[1].querySelector('.multiselect__select');
      const selected = $multiselectTo.selectedOptions;

      Array.from(selected).forEach(($option) => {
        $option.removeAttribute('selected');
        $multiselectTo.removeChild($option);
        $multiselectFrom.append($option);
      });

      $multiselect.setAttribute('id', cloneMultiselectId);
    });

    this.$controls.append($clone);

    $cloneTooltips.forEach(($tooltip) => {
      const $tooltipPopup = $tooltip.parentNode.querySelector('.tooltip__popup');
      const tooltipPopupId = $tooltipPopup.getAttribute('id');
      const tooltipDescribedBy = $tooltip.getAttribute('aria-describedby');

      $tooltip.setAttribute('aria-describedby', `${tooltipDescribedBy}_${newIndex}`);
      $tooltipPopup.setAttribute('id', `${tooltipPopupId}_${newIndex}`);

      attachTooltip($tooltip);
    });

    $cloneMultiselect.forEach(($multiselect) => {
      // eslint-disable-next-line no-unused-vars
      const clonedMultiselect = attachMultiselect($multiselect);
    });

    if (this.$controls.lastChild.querySelector('.input')) {
      this.$controls.lastChild.querySelector('.input').focus();
    }

    if (newIndex === this.maxElementCount) {
      this.disableButton();
    }
  }
}
