// @TODO: The code is not as clear and generic as it could be. Works for now but improve later!
// 1. Make the Modal generic (Cookie name, etc.)
// 2. Add unique ID (allow multiple Modals to exist ;)
// 3. Make Modal triggerable
// ... etc....

class Modal {
  constructor($el) {
    this.$el = $el;
    this.$body = document.querySelector('body');
    this.$modalInner = this.$el.querySelector('.modal__inner');
    this.$closeModalBtn = this.$el.querySelector('.modal__close');
    this.$body.classList.add('modal--open');

    this.closeBinded = this.closeModal.bind(this);
    this.outsideClickBinded = this.outsideClick.bind(this);
    this.keydownBinded = this.onKeydown.bind(this);

    this.initModal();
  }

  initModal() {
    this.$body.addEventListener('click', this.outsideClickBinded);
    this.$closeModalBtn.addEventListener('click', this.closeBinded);
    document.addEventListener('keydown', this.keydownBinded);
    this.$body.addEventListener('touchstart', this.outsideClickBinded);

    this.getCookie();
  }

  closeModal() {
    this.Setcookie('Modal');

    this.$body.classList.remove('modal--open');
    this.$closeModalBtn.removeEventListener('click', this.closeBinded);
    this.$body.removeEventListener('click', this.outsideClickBinded);
    this.$body.removeEventListener('touchstart', this.outsideClickBinded);
    document.removeEventListener('keydown', this.keydownBinded);
  }

  onKeydown(event) {
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closeModal();
    }
  }

  outsideClick(event) {
    if (event.target.closest('.modal__inner')) return;

    this.closeModal();
  }

  getCookie() {
    const galleryCookie = 'Modal';
    let galleryName = '';
    let isCookies = false;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf('Modal') === 0) {
        galleryName = c.substring(galleryCookie.length, c.length);
      }
    }

    if (galleryName) {
      this.closeModal();
      isCookies = true;
    }

    return isCookies;
  }

  Setcookie(cname) {
    const days = 3500;
    const myDate = new Date();
    myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));

    document.cookie = cname + '=allow'.concat(';expires=').concat(myDate.toGMTString());
  }
}

document.querySelectorAll('.modal').forEach($el => new Modal($el));
