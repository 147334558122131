import ResizeObserver from 'resize-observer-polyfill';
import getTarget from 'javascripts/utils/get-target';
import { MEDIA_QUERIES } from 'javascripts/constants';

// Knowledgebase Filter
export default class KbFilterLinks {
  constructor($knowledgebaseFilterLinks, resizeObserver) {
    // Get references
    this.$knowledgebaseFilterLinks = $knowledgebaseFilterLinks;
    this.$items = this.$knowledgebaseFilterLinks.querySelector('.knowledgebase-filter__items');
    this.items = this.$items.querySelectorAll('.knowledgebase-filter__item');
    this.$select = this.$knowledgebaseFilterLinks.querySelector('.knowledgebase-filter__select');

    // Is section list
    this.isSectionList = this.$knowledgebaseFilterLinks.classList.contains('knowledgebase-filter__links--section');

    // Register with observer
    resizeObserver.observe(this.$knowledgebaseFilterLinks);

    // Outside click
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);

    // Register events
    this.$select.addEventListener('click', this.onSelect.bind(this));
    this.$select.addEventListener('keydown', this.onSelectKeydown.bind(this));
    window.addEventListener('hashchange', this.onHashchange.bind(this));

    this.onHashchange();
  }

  static onResize(knowledgebaseFiltersLinks) {
    knowledgebaseFiltersLinks.forEach((knowledgebaseFilterLinks) => {
      const $knowledgebaseFilterLinks = getTarget(knowledgebaseFilterLinks.target, '.knowledgebase-filter__links');
      const $items = $knowledgebaseFilterLinks.querySelector('.knowledgebase-filter__items');

      // Reset back
      $knowledgebaseFilterLinks.classList.remove('knowledgebase-filter__links--menu');
      $knowledgebaseFilterLinks.classList.add('knowledgebase-filter__links--list', 'knowledgebase-filter__links--uninitalized');

      // Get widths and collapse status
      const kbFilterLinksWidth = $knowledgebaseFilterLinks.getBoundingClientRect().width;
      let itemsWidth = 1;
      $items.querySelectorAll('.knowledgebase-filter__item-wrap').forEach(($item) => {
        itemsWidth += $item.getBoundingClientRect().width - 1;
      });
      const isCollapsed = itemsWidth > kbFilterLinksWidth;

      // Switch between list and menu
      KbFilterLinks.switchBetweenListAndMenu($knowledgebaseFilterLinks, isCollapsed);
    });
  }

  static switchBetweenListAndMenu($knowledgebaseFilterLinks, isCollapsed) {
    const $items = $knowledgebaseFilterLinks.querySelector('.knowledgebase-filter__items');

    // Switch class
    $knowledgebaseFilterLinks.classList[isCollapsed ? 'add' : 'remove']('knowledgebase-filter__links--menu');
    $knowledgebaseFilterLinks.classList[isCollapsed ? 'remove' : 'add']('knowledgebase-filter__links--list');

    // Remove uninitalized class
    $knowledgebaseFilterLinks.classList.remove('knowledgebase-filter__links--uninitalized');

    $items.hidden = isCollapsed;

    // Update list items
    $items.querySelectorAll('.knowledgebase-filter__item').forEach(($item) => {
      // Update aria props for current active item
      if (isCollapsed && $item.getAttribute('aria-selected') === 'true') {
        $item.removeAttribute('aria-selected');
        $item.setAttribute('aria-checked', 'true');
      } else if (!isCollapsed && $item.getAttribute('aria-checked') === 'true') {
        $item.removeAttribute('aria-checked');
        $item.setAttribute('aria-selected', 'true');
      }
    });
  }

  initSectionList() {
    // Remove uninitalized class
    this.$knowledgebaseFilterLinks.classList.remove('knowledgebase-filter__links--uninitalized');

    // Switch between list and menu
    const switchOnMediaQueryChange = (mq) => {
      KbFilterLinks.switchBetweenListAndMenu(this.$knowledgebaseFilterLinks, !mq.matches);
    };

    // Init media query
    const mql = window.matchMedia(MEDIA_QUERIES.m);
    mql.addListener(switchOnMediaQueryChange);
    switchOnMediaQueryChange(mql);
  }

  openMenu() {
    // Set state
    this.$select.setAttribute('aria-expanded', 'true');
    this.$items.hidden = false;

    // Get current active item
    const $checked = this.$items.querySelector('[aria-checked="true"]');
    $checked.focus();

    // Catch outside clicks
    document.addEventListener('click', this.onOutsideClickBinded);
  }

  closeMenu() {
    // Set state
    this.$select.setAttribute('aria-expanded', 'false');
    this.$items.hidden = true;
  }

  toggleMenu() {
    const expanded = this.$select.getAttribute('aria-expanded') === 'true';
    return expanded ? this.closeMenu() : this.openMenu();
  }

  focusNext($currentItem, $startItem) {
    // Determine which item is the startItem (first or last)
    const down = $startItem === this.items[0];

    // Helper function for getting next legitimate element
    const move = ($el) => {
      const $nextThing = (down
        ? $el.parentNode.nextElementSibling : $el.parentNode.previousElementSibling);

      return ($nextThing && $nextThing.firstElementChild) || $startItem;
    };

    // Move and focus
    const $nextItem = move($currentItem);
    $nextItem.focus();
  }

  openByHash() {
    if (window.location.hash) {
      const $target = document.getElementById(window.location.hash.substring(1));

      if ($target && $target.matches('.knowledgebase-filter__item')) {
        $target.click();
        return true;
      }
    }

    return false;
  }

  onOutsideClick(event) {
    if (!this.$items.contains(event.target) && !this.$select.contains(event.target)) {
      document.removeEventListener('click', this.onOutsideClickBinded);
      this.closeMenu();
    }
  }

  onHashchange() {
    this.openByHash();
  }

  onSelect() {
    this.toggleMenu();
  }

  onSelectKeydown(event) {
    // Also toggle on down arrow
    if (event.keyCode === 40) {
      if (this.$items.hidden) {
        this.openMenu();
      } else {
        this.items[0].focus();
      }
    }

    // close menu on up arrow
    if (event.keyCode === 38) {
      this.closeMenu();
    }
  }

  isSamePage($link) {
    return (
      $link.protocol !== window.location.protocol
      || $link.host !== window.location.host
      || $link.pathname !== window.location.pathname
      || $link.search !== window.location.search
    ) === false;
  }
}

// Init
const observer = new ResizeObserver(KbFilterLinks.onResize);
document.querySelectorAll('.knowledgebase-filter__links').forEach($knowledgebaseFilterLinks => new KbFilterLinks($knowledgebaseFilterLinks, observer));
