import h from 'hyperscript';
import classNames from 'classnames';
import TomSelect from 'tom-select';
import Choices from 'choices.js';

export default function Select({
  id,
  name,
  disabled = false,
  required = false,
  invalid = false,
  descriptionId = false,
  labelId = false,
  errorId = false,
  classes = false,
  hasPleaseSelectOption = true,
  pleaseSelectOptionText = 'Bitte Auswählen',
  options = [
    {
      value: 'Value 1',
      text: 'Option 1',
    },
    {
      value: 'Value 2',
      text: 'Option 2',
    },
  ],
}) {
  let $pleaseSelectOptionEl = false;

  if (hasPleaseSelectOption) {
    $pleaseSelectOptionEl = h('option.select__hint', {
      disabled: 'disabled',
      selected: 'selected',
      value: '',
    }, pleaseSelectOptionText);
  }

  const $select = h('select', {
    className: classNames(
      'select',
      classes && classes,
    ),
    id,
    name,
    required,
    disabled,
    'aria-invalid': invalid && 'true',
    'aria-describedby': descriptionId,
    'aria-labelledby': labelId,
    'aria-errormessage': errorId,
  });

  if ($pleaseSelectOptionEl) $select.appendChild($pleaseSelectOptionEl);

  if (options) {
    options.forEach((option) => {
      const $optionEl = h('option', {
        value: option.value || option.uid,
        selected: option.selected,
      }, option.text || option.title);

      $select.appendChild($optionEl);
    });
  }

  return $select;
}

document.querySelectorAll('.js-select-choices').forEach(($el) => {
  const choicesOptions = {
    itemSelectText: '',
    searchPlaceholderValue: 'Suchen',
    searchResultLimit: 10,
    searchEnabled: $el.dataset.searchEnabled || false,
    shouldSort: false,
  };
  const choices = new Choices($el, choicesOptions); // eslint-disable-line
});

document.querySelectorAll('.select--multi-checkbox-search').forEach(($toggle) => {
  const selectMulti = new TomSelect($toggle, { // eslint-disable-line
    plugins: ['dropdown_input', 'checkbox_options'],
    placeholder: 'Suchen',
  });

  const placeholderText = $toggle.querySelector('.select__hint').innerText;
  const selectControl = $toggle.nextSibling.querySelector('.ts-control');
  selectControl.setAttribute('data-before', placeholderText);
});

document.querySelectorAll('.select--multi-checkbox').forEach(($toggle) => {
  const selectMultiCheckbox = new TomSelect($toggle, { // eslint-disable-line
    hidePlaceholder: true,
    plugins: ['checkbox_options'],
  });
});
