// Set hover style on current hovered radio and all predecessors
document.addEventListener('mouseover', (event) => {
  const $targetRadio = event.target.closest('.rating-fieldset__radio');
  if ($targetRadio) {
    const $targetFieldset = event.target.closest('.rating-fieldset');
    const $targetFieldsetRadios = $targetFieldset.querySelectorAll('.radio__input');
    const $targetInputValue = parseInt($targetRadio.querySelector('.radio__input').value, 10);

    $targetFieldsetRadios.forEach(($radio) => {
      if (parseInt($radio.value, 10) < $targetInputValue) {
        $radio.classList.add('radio__input--hover');
      }
    });
  }
});

// Remove hover style on current hovered radio and all predecessors
document.addEventListener('mouseout', (event) => {
  const $targetRadio = event.target.closest('.rating-fieldset__radio');
  if ($targetRadio) {
    const $targetFieldset = event.target.closest('.rating-fieldset');
    const $targetFieldsetRadios = $targetFieldset.querySelectorAll('.radio__input');

    $targetFieldsetRadios.forEach(($radio) => {
      $radio.classList.remove('radio__input--hover');
    });
  }
});

// Set active style on current selected radio and all predecessors
document.addEventListener('change', (event) => {
  const $targetRadio = event.target.closest('.rating-fieldset__radio');
  if ($targetRadio) {
    const $targetFieldset = event.target.closest('.rating-fieldset');
    const $targetFieldsetRadios = $targetFieldset.querySelectorAll('.radio__input');
    const $targetInputValue = parseInt($targetRadio.querySelector('.radio__input').value, 10);

    $targetFieldsetRadios.forEach(($radio) => {
      $radio.classList.remove('radio__input--checked');

      if (parseInt($radio.value, 10) <= $targetInputValue) {
        $radio.classList.add('radio__input--checked');
      }
    });
  }
});
