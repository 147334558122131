document.querySelectorAll('.js-toggle-accordion').forEach(($toggle) => {
  $toggle.addEventListener('click', (event) => {
    const $target = event.currentTarget;

    if ($target) {
      const $accordionPanel = $target.closest('.accordion__item');
      const accordionContentVisible = $toggle.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($toggle.getAttribute('aria-controls'));

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!accordionContentVisible).toString());

      const contentClass = $content.classList[0];
      // Switch content visiblity
      if (accordionContentVisible) {
        $content.classList.remove(`${contentClass}--visible`);
        $accordionPanel.classList.remove('accordion__item--open');
      } else {
        $content.classList.add(`${contentClass}--visible`);
        $accordionPanel.classList.add('accordion__item--open');
      }
    }
  });
});
