import { tns } from 'tns';

export const SELECTOR_EVENTSLIDER = '.bJS_eventslider';
const SELECTOR_SLIDEREL = '.bJS_eventslider-elements';

export class Eventslider {
    constructor(elements) {
        elements.forEach((slider) => {
            this.initSlider(slider);
        });
    }

    initSlider (slider) {
        const sliderElements = slider.querySelector(SELECTOR_SLIDEREL);
        this.slider = tns({
            container: sliderElements,
            controls: false,
            mouseDrag: false,
            navPosition: 'bottom',
            preventScrollOnTouch: 'auto',
            autoplay: true,
            autoHeight: true,
            autoplayTimeout: 6000,
            speed: 500,
            autoplayButtonOutput: false,
            autoplayHoverPause: true,
            items: 1,
            responsive: {
                768: {
                    items: 2,
                    gutter: 30
                },
                1280: {
                    items: 3,
                    gutter: 30
                }
            }
        });
    };
}
