import randomId from 'javascripts/utils/random-id';
import { createPopper } from '@popperjs/core';
import h from 'hyperscript';
import icon from 'components/_particles/icon/generate-icon';

export default class Tooltip {
  constructor($el, $tooltip, config) {
    this.$el = $el;
    this.$tooltip = $tooltip;
    this.popper = null;
    this.config = {
      closeButtonClass: 'button.tooltip__close',
      openOnHover: true,
      closeOnFocusOut: true,
      placement: this.$el.dataset.placement || 'left',
      orientations: [
        'right',
        'right-start',
        'bottom',
        'left',
        'left-start',
        'top',
      ],
      ...config,
    };
    this.$close = $tooltip.querySelector(this.config.closeButtonClass);

    // Bind events
    if (this.config.openOnHover) {
      this.onMouseenterBinded = this.onMouseenter.bind(this);
      this.onMouseleaveBinded = this.onMouseleave.bind(this);
    }
    this.onClickBinded = this.onClick.bind(this);
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);
    this.onTooltipFocusoutBinded = this.onTooltipFocusout.bind(this);
    this.onCloseClickBinded = this.onCloseClick.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$el.addEventListener('mouseenter', this.onMouseenterBinded);
    this.$el.addEventListener('click', this.onClickBinded);
    this.$close.addEventListener('click', this.onCloseClickBinded);
  }

  create() {
    // Create popper
    this.popper = createPopper(
      this.$el,
      this.$tooltip,
      {
        placement: this.config.placement,
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: this.config.orientations,
            },
          },
          {
            name: 'arrow',
            options: {
              element: this.$tooltip.querySelector('.tooltip__arrow'),
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      },
    );
  }

  onMouseenter() {
    this.$el.addEventListener('mouseleave', this.onMouseleaveBinded);
    this.show();
  }

  onMouseleave() {
    this.$el.removeEventListener('mouseleave', this.onMouseleaveBinded);
    this.hide();
  }

  onClick(event) {
    event.preventDefault();

    if (this.config.openOnHover) {
      this.$el.removeEventListener('mouseleave', this.onMouseleaveBinded);
      this.$el.removeEventListener('mouseenter', this.onMouseenterBinded);
    }
    this.$close.hidden = false;
    this.show();

    document.addEventListener('click', this.onOutsideClickBinded);

    if (this.config.closeOnFocusOut) {
      this.$tooltip.addEventListener('focusout', this.onTooltipFocusoutBinded);
    }

    if (this.config.onClick) {
      this.config.onClick();
    }
  }

  onOutsideClick(event) {
    const $target = event.target;

    if (this.isInside($target)) {
      return;
    }

    this.hideAfterClick();
  }

  onTooltipFocusout(event) {
    const $target = event.relatedTarget;

    if (this.isInside($target)) {
      return;
    }

    this.hideAfterClick();
  }

  onCloseClick(event) {
    event.preventDefault();
    this.hideAfterClick();
    this.$el.focus();
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.hide();
    }
  }

  show() {
    this.$tooltip.setAttribute('aria-hidden', 'false');
    this.create();

    document.body.addEventListener('keydown', this.onKeydownBinded);
  }

  hide() {
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$tooltip.setAttribute('aria-hidden', 'true');
    this.$el.focus();
    this.destroy();
  }

  destroy() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  }

  hideAfterClick() {
    this.hide();
    this.$close.hidden = true;

    document.removeEventListener('click', this.onOutsideClickBinded);
    this.$tooltip.removeEventListener('focusout', this.onTooltipFocusoutBinded);

    if (this.config.openOnHover) {
      this.$el.addEventListener('mouseenter', this.onMouseenterBinded);
    }
  }

  isInside($target) {
    return (
      this.$tooltip.contains($target)
      || this.$tooltip === $target
      || this.$el.contains($target)
      || this.$el === $target
    );
  }
}

export const attachTooltip = ($el) => {
  const title = $el.getAttribute('title');
  const describedBy = $el.getAttribute('aria-describedby');

  let $tooltip = false;
  let $text = false;
  // if (!title) {
  //   return null;
  // }

  if (title) {
    $el.removeAttribute('title');
    const id = `tooltip-${randomId()}`;

    if (!$el.hasAttribute('aria-label')) {
      $el.setAttribute('aria-label', 'Begriffserklärung anzeigen');
    }

    // Templates
    $text = h('p.tooltip__text', { attrs: { tabindex: '0' } }, title);
    const $arrow = h('.tooltip__arrow');

    const $close = h(
      'button.tooltip__close',
      {
        type: 'button',
        title: 'Schließen',
        hidden: true,
      },
      icon({
        icon: 'close',
      }),
    );

    $tooltip = h(
      `.tooltip__popup#${id}`,
      {
        attrs: {
          'aria-hidden': 'true',
          role: 'tooltip',
        },
      },
      $text,
      $arrow,
      $close,
    );

    $el.parentNode.insertBefore($tooltip, $el.nextSibling);
  } else if (describedBy) {
    $tooltip = document.getElementById(describedBy);
    $text = $tooltip.querySelector('.tooltip__text');
  } else {
    return false;
  }

  return new Tooltip($el, $tooltip, {
    onClick: () => {
      $text.focus();
    },
  });
};

document
  .querySelectorAll('.js-tooltip')
  .forEach(attachTooltip);
