import FormValidation from '../../../javascripts/utils/form-validation';
import ToggleFormFieldsGroup from './form-toggle-fields-group';
import MultiplicateFormFieldsGroup from './form-multiplicate-fields-group';

document.querySelectorAll('.js-form-client-side-validation').forEach($el => new FormValidation($el));

document.querySelectorAll('[data-trim-email="1"]').forEach(($el) => {
  $el.addEventListener('blur', () => {
    const value = $el.value.trim();

    if (value) {
      $el.value = value;
    }
  });
});

document.querySelectorAll('.js-toggle-form-fields-group').forEach($el => new ToggleFormFieldsGroup($el));

document.querySelectorAll('.js-form-fields-group-multiplicator').forEach($el => new MultiplicateFormFieldsGroup($el));
