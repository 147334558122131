export const ICON_SPRITE_URL = window.ina.ICON_SPRITE_URL || '/icons/icons.svg';
export const LANG = document.documentElement.lang || 'de';
export const API_URL = window.ina.API_URL || '/api/';

export const BREAKPOINTS = {
  xs: '0px',
  m: '768px',
  l: '1024px',
  xl: '1260px',
  xxl: '1920px',
};

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 480px)',
  l: '(min-width: 768px)',
  xl: '(min-width: 1024px)',
};
